import { Controller } from "stimulus"
import Fetch from "../modules/fetch";

export default class extends Controller {
  static values = {
    active: Boolean,
    profile: String,
    current: Number,
    previous: Number,
    queueTime: {type: Number, default: 1000},
  };
  static targets = [
    "tick",
    "notch",
    "dial"
  ]

  initialize() {
    if (this.currentValue) {
      this.setCurrentValue(this.currentValue);
    }
  }

  connect() {
    this.boundDragStart = this.dragStart.bind(this)
    this.element.addEventListener("mousedown", this.boundDragStart)
    this.boundDrag = this.drag.bind(this)
    this.boundDragStop = this.dragStop.bind(this)
    this.boundCallback = this.callback.bind(this)
  }

  toggle(event) {
    if (this.activeValue) {
      return this.stop()
    }
    this.start()
  }

  stop() {
    this.previousValue = this.currentValue
    this.setCurrentValue(0)
    this.element.classList.remove('active')
    this.send()
  }

  start() {
    if (this.currentValue == 0) {
      let value = (this.previousValue > 0) ? this.previousValue : 3;
      this.setCurrentValue(value)
    }
    this.element.classList.add('active')
    this.send()
  }

  increaseValue() {
    if (this.currentValue === 5) {
      return
    }
    this.setCurrentValue(this.currentValue + 1)
    this.activate();
    this.queueSend()
  }

  decreaseValue() {
    if (this.currentValue <= 1) {
      return
    }
    this.setCurrentValue(this.currentValue - 1)
    this.activate();
    this.queueSend()
  }

  resetDrag() {
    this.cumulativeX = 0
    this.cumulativeY = 0
  }

  dragStart(event) {
    event.preventDefault();
    if (event.buttons == 1) {
      this.originalMousePosition = [event.screenX, event.screenY]
      document.addEventListener("mousemove", this.boundDrag)
      document.addEventListener("mouseup", this.boundDragStop)
      this.resetDrag()
    }
  }

  drag(event) {
    event.preventDefault();
    this.cumulativeX += event.movementX
    this.cumulativeY += event.movementY

    if (this.cumulativeX <= -30) {
      this.decreaseValue()
      this.resetDrag()
    }
    else if (this.cumulativeX >= 30) {
      this.increaseValue()
      this.resetDrag()
    }

    if (this.cumulativeY <= -30) {
      this.increaseValue()
      this.resetDrag()
    }
    else if (this.cumulativeY >= 30) {
      this.decreaseValue()
      this.resetDrag()
    }
  }

  dragStop(event) {
    document.removeEventListener("mousemove", this.boundDrag)
    document.removeEventListener("mouseup", this.boundDragStop)
  }

  activate() {
    this.activeValue = true;
    this.element.classList.add('active')
  }

  setValue(event) {
    let value = parseInt(event.currentTarget.dataset.value)
    this.setCurrentValue(value)
    this.activate();
    this.send();
  }

  setCurrentValue(value) {
    this.currentValue = value
    this.activeTick = value
    this.activeNotch = value
    this.dialRotation = value
  }

  queueSend() {
    if (this.saveTimer) {
      window.clearTimeout(this.saveTimer);
    }
    this.saveTimer = window.setTimeout(() => {
      this.send()
    }, this.queueTimeValue);
  }

  send() {
    let data = {sound_profile: {filter_mode: 'tuners'}}
    data.sound_profile[this.profileValue] = this.currentValue;

    console.log('KNOB Sending data to URL:', this.url);
    console.log('KNOB Data being sent:', data);
    new Fetch(this.url, this.boundCallback, {method: 'PATCH', accept:'text/vnd.turbo-stream.html', body:data});
  }

  callback(response) {
    Turbo.renderStreamMessage(response.data)
  }

  set activeTick(value) {
    this.tickTargets.forEach(tick => {
      tick.classList.toggle('active', parseInt(tick.dataset.value) === this.currentValue)
    });
  }

  set activeNotch(value) {
    this.notchTargets.forEach(notch => {
      notch.classList.toggle('active', parseInt(notch.dataset.value) === this.currentValue)
    });
  }

  set dialRotation(value) {
    this.dialTarget.setAttribute("transform", this.currentRotation)
  }

  get url() {
    return '/sound_profile'
  }

  get currentRotation() {
    return 'rotate(' + (this.currentValue * 60) + ')'
  }

}




